<template lang="pug">
  PostForm(:data="data")
  //- pre {{ data }}
</template>

<script>
import PostForm from './PostForm'

export default {
  name: 'PostEdit',
  components: {
    PostForm
  },
  data () {
    return {
      data: {
        id: undefined
      }
    }
  },
  methods: {
    async getDetail() {
      if (!this.$route.params.id) return;

      const result = await this.$http.get(`post/${this.$route.params.id}`);
      this.data = result;
    },
  },
  created() {
    this.getDetail();
  }
}
</script>

<style lang="less" scoped></style>